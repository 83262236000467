.horizontal-line{
    border: 1px solid black;
    width: 90%;
    background:black;
    margin:50px auto;
}
.footerBox{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1% 0% 3% 0%;
    cursor: pointer;
}
.footerBoxTitle{
    color: #E1593D;
    margin-bottom: 35px;
    font-size: 28px;
}
.footerBox ul{
    list-style-type: none;
    padding: 0px;
    text-align: center;
}
.footerBox ul li{
    margin-bottom: 16px;
}
.footerLink, .footerLink:hover{
    text-decoration: none;
    color:#000 ;
}
.footerImg{
    width:20px;
    height:20px;
}
.footerIcon{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
/* Phone greater 300px */
@media (min-width : 370px ) and (max-width: 380px){
    .footerBox{
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        padding: 1% 0% 3% 0%;
    }
    .footerIcon {
        margin: 1px auto;
    }
    .footerBoxTitle {
        margin-bottom: 15px;
        margin-top: 25px;
    }
    .horizontal-line {
        margin: 30px auto;
    }
    .copyrightBox{
        text-align: center;
        font-size: 12px;
    }
    .copyright{
        width: 90%;
        margin: 0px auto;
    }
}
/* Phone greater 400px */
@media (min-width : 381px ) and (max-width: 700px){
    .footerBox{
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        padding: 1% 0% 3% 0%;
    }
    .footerIcon {
        margin: 1px auto;
    }
    .footerBoxTitle {
        margin-bottom: 15px;
        margin-top: 25px;
    }
    .horizontal-line {
        margin: 30px auto;
    }
    .copyrightBox{
        text-align: center;
        font-size: 12px;
    }
    .copyright{
        width: 90%;
        margin: 0px auto;
    }
}
/* Ipad (my_iPad)*/
@media (min-width : 701px ) and (max-width: 900px){
    .footerBoxTitle {
        color: #E1593D;
        margin-bottom: 16px;
        font-size: 24px;
    }
    .footerBox {
        font-size: 15px;
    }
}
/* Ipad pro */
@media (min-width : 1001px ) and (max-width: 1250px){}
    /* Laptop   */
@media (min-width : 1251px ) and (max-width: 1300px){}
/* Laptop over inches13-2  */
@media (min-width: 1301px) and (max-width: 1400px) {}