@import url('https://fonts.googleapis.com/css2?family=Charmonman&family=Comfortaa:wght@400;600&display=swap');
*{
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Comfortaa', cursive;
}                    
html {
  scroll-behavior: smooth;
}
/* .App {
  text-align: center;
} */

button {
  border: none;
  background-color: transparent;
}

.loading-container {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 10px;
}


.loading {
  border: 4px solid #131313;
  border-radius: 50em;
  border-top: 4px solid rgb(0, 0, 0, 0);
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}