@import url('https://fonts.googleapis.com/css2?family=Charmonman&family=Comfortaa:wght@400;600&display=swap');
*{
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Comfortaa', cursive;
} 
.header{
    text-align: center;
    position: relative;
}
.adaLogo{
    width: 150px;
    margin-top: -15px;
    margin-left: 15px;
}
.nav-link.product{
    position: relative;
}
.nav-link.product::after{
    /* font-family: 'ETmodules'; */
    content: "";
    font-size: 16px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    position: absolute;
    right: -10px;
    top: 45%;
    font-weight: 800;
}
.nav-item {
    margin-right: 40px;
}
.navbarIconBox{
    margin-right: 60px;
    list-style-type: none;
    display: flex;
}
.navbarIconBox li{
    margin-left:50px;
    cursor: pointer;
}
.nav-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
}
#currentSection{
    color:  #E1593D !important;
    font-weight: bold;
} 
#currentSectionFooter{
    color:  #E1593D !important;
    font-weight: bold;
} 
.toTopIcon{
    position:fixed;
    width: 100px;
    float: right;
    right: 6%;
    bottom: 12%;
    z-index: 2000;
}
.toTop{
    position: relative; 
}
.scroll-to-next{
    position: absolute;
    bottom: 6px;
    right: 9%;
    margin: auto;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    border:none;
}
/* Phone greater 300px */
@media (min-width : 370px ) and (max-width: 380px){
    .adaLogo{
        margin-left: 0px;
    }
    .nav-item{
        padding: 5px;
        margin:0px;
        text-align: center;
    }
    .menu-icon{
        color:#000000 !important;
        font-size: 35px;
    }
    .navbar-toggler{
        margin: 15px 20px 30px 0px;
    }
    .navbar-toggler,
    .navbar-toggler:focus,
    .navbar-toggler:active,
    .navbar-toggler:focus {
        outline: none;
        box-shadow: none;
        border: none;
    }
    .nav-link, 
    .navbarIconBox{
        color: #000000 !important;
        padding: 15px 20px;
        margin:10px;
        text-transform:uppercase;
        margin-bottom: 20px;
        box-shadow: 0 0 5px #00000015;
        border-radius: 10em;
    }
    #currentSection{
        color:  #E1593D !important;
        font-weight: bold;
        box-shadow: 0 0 5px #E1593D;
    } 
    #currentSectionFooter{
        color:  #E1593D !important;
        font-weight: bold;
    } 
    .navbarIconBox li{
        margin: 0px auto;
    }
}
/* Phone greater 400px */
@media (min-width : 381px ) and (max-width: 800px){
    .adaLogo{
        margin-left: 0px;
    }
    .nav-item{
        padding: 5px;
        margin:0px;
        text-align: center;
    }
    .menu-icon{
        color:#000000 !important;
        font-size: 35px;
    }
    .navbar-toggler{
        margin: 15px 20px 30px 0px;
    }
    .navbar-toggler,
    .navbar-toggler:focus,
    .navbar-toggler:active,
    .navbar-toggler:focus {
        outline: none;
        box-shadow: none;
        border: none;
    }
    .nav-link, 
    .navbarIconBox{
        color: #000000 !important;
        padding: 15px 20px;
        margin:10px;
        text-transform:uppercase;
        margin-bottom: 20px;
        box-shadow: 0 0 5px #00000015;
        border-radius: 10em;
    }
    .navbarIconBox li{
        margin: 0px auto;
    }
}
/* Ipad (my_iPad)*/
@media (min-width : 801px ) and (max-width: 1000px){}
/* Ipad pro */
@media (min-width : 1001px ) and (max-width: 1250px){}
    /* Laptop   */
@media (min-width : 1251px ) and (max-width: 1300px){}
/* Laptop over inches13-2  */
@media (min-width: 1301px) and (max-width: 1400px) {}