 /* First Row */
 .firstRowImg{
    width: 100%;
}
.firstRow{
    position: relative;
    text-align: center;
    color: #343A40;
}
.firstRowBox{
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
}
.firstRowBoxTitle{
    font-size: 64px;
}
.firstRowBoxText{
    font-size: 24px;
}

 /* Second Row */
 .secondRowImg{
    width: 100%;
}
.secondRow{
    position: relative;
    text-align: center;
    color: #fff;
}
.secondRowBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.secondRowBoxTitle{
    font-size: 45px;
}
.secondRowBoxText{
    font-size: 20px;
    width: 63%;
    margin: 1px auto;
}

/* Third Row */
.thirdRow{
    width: 100%;
    display: flex;
}
.thirdRowBox{
    width: 35%;
    background-color: #e8beb5;
    padding: 9% 3%;
}
.thirdRowBoxTitle{
    font-size: 45px;
}
.thirdRowBoxText{
    font-size: 20px;
    margin-bottom: 5px;
}
.homeUnderline {
    width: 64px;
    height: 0;
    background: #000000;
    border: 0.5px solid #000000;
    margin: 25px 0;
}

.thirdRowBoxImg{
    width: 65%;
    background-color: #000;
}
.thirdRowImg{
    width: 100%;
}
/* Four Row */
.fourRowImg{
    width: 100%;
}
.fourRow{
    position: relative;
    text-align: center;
    color: #fff;
}
.fourRowBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.fourRowBoxTitle{
    font-size: 45px;
}
.fourRowBoxText{
    font-size: 20px;
    width: 100%;
    margin: 8px auto;
    text-align: left;
}
/* Contact */
.contactBox{
    text-align: center;
    padding: 3% 10%;
    color: #000;
}
.contact-line{
    display: flex;
    justify-content: center;
    align-items: center;
}
.line-sm{
    height: 1px;
    width: 65px;
    background: #000000;
}
.line-lg{
    height: 4px;
    width: 55px;
    background: #E1593D;;
}
.contactBoxTitle{
    font-size: 45px;
}
.contactBoxText{
    font-size: 20px;
}
.contact{
    padding: 5% 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.contact ul{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin:3% 0%;
}
.contact ul li>ul {
    display: flex;
    flex-direction: row;
}
.contact ul li> ul li:last-child{
    margin: 1px 0px 9px 15px;

}
.mapImg{
    width: 400px;
    height: 300px;
}

/* Phone greater 300px */
@media (min-width : 370px ) and (max-width: 395px){
    .firstRow{
        padding: 22% 0% 0% 0%;
    }
    .firstRowBox{
        top:65%;
        left: 65%;
    }
    .firstRowBoxTitle{
        font-size: 30px;
    }
    .firstRowBoxText,
    .secondRowBoxText,
    .thirdRowBoxText{
        font-size: 10px;
        width: 100%;
    }
    .secondRowBoxTitle{
        font-size: 18px;
    }
    .thirdRowBoxTitle{
        font-size: 18px;
        margin-bottom: 0px;
    }
    .thirdRowBox{
        padding: 6% 0% 6% 1%;
    }
    .homeUnderline {
        border-top: 1px solid #000000;
        width: 51%;
        margin: 10px 1px;
    }
    .fourRowBoxTitle{
        font-size: 18px;
    }
    .fourRowBoxText{
        font-size: 10px;
        width: 118%;
        margin: 8px auto;
        text-align: left;
    }
    .contactBox{
        padding: 3% 6%;
    }
    .line-sm{
        height: 1px;
        width: 40px;
        background: #000000;
    }
    .line-lg{
        height: 4px;
        width: 30px;
        background: #E1593D;;
    }
    .contactBoxTitle{
        font-size: 18px;
    }
    .contactBoxText{
        font-size: 10px;
        margin-top: 5px;
    }
    .contact{
        flex-direction: column;
        justify-content: space-around;
    }
    .contact ul{
        margin:3% auto;
    }
    .mapImg{
        width: 300px;
        height: 200px;
    }
}
/* Phone greater 400px */
@media (min-width : 396px ) and (max-width: 700px){
    .firstRow{
        padding: 22% 0% 0% 0%;
    }
    .firstRowBox{
        top:67%;
        left: 65%;
    }
    .firstRowBoxTitle{
        font-size: 44px;
    }
    .firstRowBoxText{
        font-size: 14px;
    }
    .secondRowBoxTitle{
        font-size: 28px;
    }
    .secondRowBoxText{
        font-size: 14px;
        width: 117%;
        margin: 1px 0px 0px -20px;
    }
    .thirdRowBoxTitle{
        font-size: 28px;
        margin-bottom: 0px;
    }
    .thirdRowBoxText{
        font-size: 14px;
    }
    .thirdRowBox{
        padding: 6% 0% 6% 2%;
    }
    .homeUnderline {
        border-top: 1px solid #000000;
        width: 51%;
        margin: 10px 1px !important;
    }
    .fourRowBoxTitle{
        font-size: 18px;
    }
    .fourRowBoxText{
        font-size: 10px;
        width: 118%;
        margin: 8px auto;
        text-align: left;
    }
    .contactBox{
        padding: 3% 6%;
    }
    .line-sm{
        height: 1px;
        width: 40px;
        background: #000000;
    }
    .line-lg{
        height: 4px;
        width: 30px;
        background: #E1593D;;
    }
    .contactBoxTitle{
        font-size: 18px;
    }
    .contactBoxText{
        font-size: 10px;
        margin-top: 5px;
    }
    .contact{
        flex-direction: column;
        justify-content: space-around;
    }
    .contact ul{
        margin:3% auto;
    }
    .mapImg{
        width: 300px;
        height: 200px;
    }
}
/* Ipad (mini)*/
@media (min-width : 701px ) and (max-width: 900px){
    .firstRow{
        padding: 10% 0% 0% 0%;
    }
    .firstRowBox{
        top:67%;
        left: 65%;
    }
    .firstRowBoxTitle{
        font-size: 44px;
    }
    .firstRowBoxText{
        font-size: 18px;
    }
    .secondRowBoxTitle{
        font-size: 28px;
    }
    .secondRowBoxText{
        font-size: 18px;
        width: 117%;
        margin: 1px 0px 0px -20px;
    }
    .thirdRowBoxTitle{
        font-size: 28px;
        margin-bottom: 0px;
    }
    .thirdRowBoxText{
        font-size: 18px;
    }
    .thirdRowBox{
        padding: 6% 0% 6% 2%;
    }
    .homeUnderline {
        width: 64px;
        height: 0;
        background: #000000;
        border: 1px solid #000000;
        margin: 15px 0;
    }
    .fourRowBoxTitle{
        font-size: 18px;
    }
    .fourRowBoxText{
        font-size: 18px;
        width: 118%;
        margin: 8px auto;
        text-align: left;
    }
    .contactBox{
        padding: 3% 6%;
    }
    .contactBoxTitle{
        font-size: 20px;
    }
    .contactBoxText{
        font-size: 16px;
        margin-top: 5px;
    }
    .contact{
        flex-direction: column;
        justify-content: space-around;
    }
    .contact ul{
        margin:3% auto;
    }
    .mapImg{
        width: 300px;
        height: 200px;
    }
    .homeUnderline {
        border-top: 1px solid #000000;
        width: 40%;
        margin: 15px 3px;
    }
}
/* Ipad pro */
@media (min-width : 901px ) and (max-width: 1250px){
    .firstRowBox{
        top:60%;
        left: 65%;
    }
    .contactBox{
        padding: 3% ;
    }
    .contact ul{
        margin:3% auto;
    }
    .contact{
        flex-direction: row;
        justify-content: space-around;
    }
}
    /* Laptop   */
@media (min-width : 1251px ) and (max-width: 1300px){}
/* Laptop over inches13-2  */
@media (min-width: 1301px) and (max-width: 1400px) {}